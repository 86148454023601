import React from 'react';
import useMobile from '../hooks/useMobile';
import styled from 'styled-components/macro';

const BreatheBg = styled.div`
  --bg-width: 56.25rem;
  position: fixed;
  bottom: 0;
  left: ${(props) =>
    props.isCenter ? `0` : `calc(100% - (var(--bg-width) - 5rem))`};
  right: 0;
  margin: auto;
  width: var(--bg-width);
  height: ${(props) => (props.isCenter ? `100%` : `92%`)};
  background-image: url('/images/body.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: -1;
  &.mobile-bg {
    left: calc(32% - 28.063rem);
    top: ${(props) => (props.header ? `80px` : `0`)};
    bottom: auto;
  }
`;

const Breathe = ({hideMobile, isCenter, mobileBG, header}) => {
  const isMobile = useMobile();
  if (hideMobile && isMobile) {
    return <></>;
  }
  return (
    <BreatheBg
      isCenter={isCenter}
      header={header}
      className={`${mobileBG && 'mobile-bg'} body-bg`}
    />
  );
};

export default Breathe;
