import {createGlobalStyle} from 'styled-components';
import {TABLET} from '../constants/breakpoints';
import {MOBILE} from '../constants/mediaQueries';
import {LinkIconSize, mastheadHeight} from '../constants/sizes';
import accordion from './accordion.css';
import cmsContent from './cms-content.css';
import reset from './reset.css';

const GlobalStyles = createGlobalStyle`

${reset}
${accordion}
${cmsContent}

:root {
    --orange: #ff6800;
    --yellow: #ffc218;
    --red: #ff1d34;
    --dark-red: #BE1C18;
    --pink: #D00070;
    --blue: #183B54;
    --light-blue: #87A1AB;
    --turquoise: #68D2DF;
    --magenta: #830051;
    --purple: #3C1053;
    --grey: #222627;
    --medium-grey: #444444;
    --light-grey: #AAAAAA;
    --white: #FFFFFF;
    --black: #000000;
    --silver: #cccccc;
    --fog: rgba(255,255,255,0.2);
    --smoke: rgba(0,0,0,0.8);
    --light-smoke: rgba(0,0,0,0.65);
    --snowblind: rgba(255,255,255,0.8);
  }

body {
  font-family: 'Helvetica Neue',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  'Open Sans',
  'Helvetica Neue',
  sans-serif;
  font-size: 0.9375rem;
  color: var(--white);
  background-color: var(--grey);
  background-image: url('/images/background.png');
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* The overflow needs to be set to initial here so that "position: sticky" works */
  overflow: initial;
  height: auto;

  /* move background to the right where needed */
  &.bg-shift {
    background-position: 50vw center;
  }
}

#root > main {
  display: flex;
  flex-direction: column;
  height:100%;
  footer {
    margin-top: auto;
  }
}

a {
  color: #0070f3;
  text-decoration: none;
}

a * {
    pointer-events: auto;
}

a:not([class]):hover {
  text-decoration: underline;
}

p {
  font-family: Helvetica, sans-serif;
  font-size: 0.9rem;
  margin: 0 0 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1em;
}

h1 {
  font-size: 3rem;
  margin: 0 0 1rem;
}

h2 {
  font-size: 2rem;
  margin: 0 0 1rem;
}

h3 {
  font-size: 1.6rem;
  margin: 0 0 0.8rem;
}

h4 {
  font-size: 1rem;
  margin: 0 0 0.8rem;
}
h1,
h2,
h3 {
  text-shadow: 0 0.0625rem 0.5rem rgba(0, 0, 0, 0.6)
}

.no-shadow {
  box-shadow: none;
  text-shadow: none;
}

.small {
  font-size: 0.8rem;
}

.medium {
  font-size: 1rem;
}
.large {
  font-size: 1.4rem;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.navigation-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 10rem;
  height: 100%;
  background: #222627;
  padding: 6rem 0;
}
.text-center {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.pink {
  color: var(--pink);
}
.blue {
  color: var(--blue);
}
.light-blue {
  color: var(--light-blue);
}
.turquoise {
  color: var(--turquoise);
}
.magenta {
  color: var(--magenta);
}
.purple {
  color: var(--purple);
}
.grey {
  color: var(--grey);
}
.light-grey {
  color: var(--light-grey);
}
.medium-grey {
  color: var(--medium-grey);
}
.white {
  color: var(--white);
}
.fog {
  color: var(--fog);
}

.yellow {
  color: var(--yellow);
}
.red {
  color: var(--red);
}
.dark-red {
  color: var(--dark-red);
}
.orange {
  color: var(--orange);
}

.full-w {
  width: 100%;
}

.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pos-fixed {
  position: fixed;
}

.capitalise {
  text-transform: capitalize;
}

.svg-icon {
  vertical-align: middle;
  display: inline-block;
}

/* Heading fonts */
.lexia-thin {
  font-family: lexia, serif;
  font-weight: 100;
  font-style: normal;
}

.lexia-light {
  font-family: lexia, serif;
  font-weight: 300;
  font-style: normal;
}

.lexia-reg {
  font-family: lexia, serif;
  font-weight: 400;
  font-style: normal;
}

.lexia-ita {
  font-family: lexia, serif;
  font-weight: 400;
  font-style: italic;
}

.lexia-bold {
  font-family: lexia, serif;
  font-weight: 700;
  font-style: normal;
}

.lexia-bold-ita {
  font-family: lexia, serif;
  font-weight: 700;
  font-style: italic;
}

.lexia-adv {
  font-family: lexia-advertising, serif;
  font-weight: 400;
  font-style: normal;
}

.lexia-adv-ita {
  font-family: lexia-advertising, serif;
  font-weight: 400;
  font-style: italic;
}

.helvetica-neue-reg {
  font-family: 'Helvetica-Neue', Helvetica, serif;
  font-weight: 400;
  font-style: normal;
}

.helvetica-neue-bold {
  font-family: 'Helvetica-Neue', Helvetica, serif;
  font-weight: 700;
  font-style: normal;
}
.nav-item {
  position: relative;
  display: block;
  font-size: 0.8rem;
  padding: 0.8rem 2rem;
  color: var(--white);
  button& {
    width: 100%;
    text-align: left;
  }

  &.favourites {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  &.switch {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

.nav-item:before {
  opacity: 0;
  transform: translate(-100%);
  transition: all 0.4s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white);
  border-left: 0.375rem solid var(--pink);
}

.nav-item:hover {
  color: var(--blue);
}

.nav-item:hover:before,
.nav-item.active:before {
  transform: none;
  opacity: 1;
}

.nav-item.active {
  color: var(--blue);
}

/* Animations */
@keyframes breathe {
  from {
    transform: scale(1, 0.99);
  }
  to {
    transform: scale(0.99, 1);
  }
}

@keyframes slide {
  from {
      transform: translateY(-500%);
  }
  to {
      transform: translateY(0);
  }
}

@keyframes blurIn {
  from {
      filter: blur(10px);
  }
  to {
      filter: blur(0);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.link-button {
  position: relative;
  z-index: 10;
}

.min-h-screen {
  min-height: 100vh;
}

.sticky {
  position: sticky;
  top: calc(${mastheadHeight} + 0.5rem);
  z-index: 20;
}

.capitalize {
  text-transform: capitalize;
}

.data-table-header {
  background: var(--smoke);
  border-radius: 0.3125rem;
  /* margin-bottom: 1.25rem; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;

  @media ${MOBILE} {
     font-size: 0.8rem;
     padding-top: 0.625rem;
     padding-bottom: 0.625rem;
     margin-bottom: 1.25rem;
     align-items: center;
  }
}

.data-table-row {
  --col-template: auto 16% ${LinkIconSize};
  --grid_gap: 1vw;
  display: grid;
  grid-template-columns: var(--col-template);
  grid-gap: var(--grid_gap);
  align-content: space-evenly;
  align-items: center;
  padding-right: 2rem;
  padding-left: 1.8rem;
  @media ${MOBILE} {
    --col-template: auto 45% ${LinkIconSize};
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &.favourite {
    --col-template: 18% 16% 21% 23% 10% ${LinkIconSize};
  }
  &.pipeline {
    --col-template: 18% 10% 16% 24% 16% 5% ${LinkIconSize};
  }


  &.evidence {
    --col-template: 25% 25% 20% 22% ${LinkIconSize};
  }

  &.disease-area {
    --col-template: 18% 16% 21% 23% 10%  ${LinkIconSize};
    @media ${MOBILE} {
      --col-template: auto 20% 20% ${LinkIconSize};
    }
  }

  &.evidence-programme {
    --col-template: 20% 40% 20% 20% ${LinkIconSize};
    @media ${MOBILE} {
      --col-template: 20% 40% 20% 20% ${LinkIconSize};
    }
  }

  &.clinical-trial {
    --col-template: auto 14% 26% 10% 20% ${LinkIconSize};
    @media ${MOBILE} {
      --col-template: auto 25% 25% ${LinkIconSize};
    }
  }

  &.clinical-trial-fav {
    --col-template: auto 24% 16% 20% ${LinkIconSize};
    @media ${MOBILE} {
      --col-template: auto 25% 25% ${LinkIconSize};
    }
  }

  &.trial-detail {
    --col-template: 50% 50%;
    background-color: var(--smoke);
    color: var(--white);
    font-size: 0.875rem;
    border-radius: 0.2rem;
    padding-top: 1.75em;
    padding-bottom: 1.75em;

    &+.trial-detail {
      margin-top: 0.3rem;
    }
    b {
      font-weight: normal;
    }
  }
  
  div{
    overflow:visible !important;
  }
  
}

.link {
  border-radius: 0.2rem;
  color: var(--white);
  background: var(--smoke);
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0.2rem;
  transition: transform 0.3s ease;
  cursor: pointer;

  > div {
    overflow: hidden;
  }

  /* &:nth-child(even) {
    background: rgba(255, 255, 255, 1);
  }
  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.8);
  } */
  @media (hover: hover) {
    &:hover {
      transform: scale(1.015, 1.015);
    }
  }
  &:last-of-type {
    margin-bottom: 1rem;
  }
  svg {
    fill: var(--pink);
  }

  .name {
    color: var(--white);
    margin: 0 0 0.5rem 0;
  }

  .desc {
    fill: var(--white);
    color: var(--white);
    line-height: 1.25em;
    p {
       margin: 0 0 0.1rem 0;
    }
 }

  &.disease-area {
      align-items: center;
  }

 &.has-des {
    position: relative;
    overflow: hidden;
    padding-bottom: 2rem;
    @media (hover: hover) {
      &:hover {
        .designation {
          transform: none;
        }
      }
    }
  }

  @media ${MOBILE} {
    min-height: 4rem;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .name {
      font-size: 12px;
      margin-bottom: 0;
    }
    @media (hover: hover) {
      &:hover {
        padding: 1.5rem 0.6rem 1.5rem 0.6rem !important;
      }
      &:not(:hover) {
        .designation {
          left: 0px;
        }
      }
    }
  }
}

/* @media ${MOBILE} {
  #CookieReportsBannerAZ {
    display: none !important;
  }
} */

.hide-on-mobile {
  @media screen and (max-width: ${TABLET}px) {
    display: none !important;
  }
 }
.hide-on-desktop {
   @media screen and (min-width: ${TABLET + 1}px) {
     display: none !important;
   }
}
.stack >*+* {
  /* margin-top: 1.5em; */
}
.flex {
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--grid_gap, 0.5rem));
  >* {
    margin: var(--grid_gap, 0.5rem);
  }
}
.ft-sz-med {
  font-size: 1.6rem;
}
`;

export default GlobalStyles;
