import React from 'react';
import styled from 'styled-components/macro';
import { MOBILE } from '../../constants/mediaQueries';

const Wrapper = styled.div`
   margin-bottom: ${(props) => (props.noMarginBottom ? '0rem' : '5rem')};
   margin-left: auto;
   margin-right: auto;
   margin-top: 0;
   max-width: 87.5rem;
   padding: 0 6%;
   position: relative;
   width: 100%;
   height: 100%;
   ${(props) => props.landing && `padding-top: 10rem;`}
   @media ${MOBILE} {
      ${(props) => (props.landing ? 'padding-top: 6%;' : 'padding: 0 3%;')}
      margin-top: 5vh;
      &.pipeline {
         h1 {
            font-weight: 300;
            font-size: 2.25rem;
         }
      }
      h1 {
         font-size: 2rem;
         margin-bottom: 1rem;
      }
   }
`;
const ContentWrapper = ({ children, isLanding, className, noMarginBottom }) => {
   return (
      <Wrapper
         className={className ? className : ''}
         landing={isLanding}
         noMarginBottom={noMarginBottom}
      >
         {children}
      </Wrapper>
   );
};

export default ContentWrapper;
