const checkIsActive = (path, location) => {

  const href = location.pathname

  // If it is an exact match, bail early
  if (href === path) return true

  // If it is not an exact match, perhaps there's a fuzzy match…
  const splitter = '/'
  const array1 = href.split(splitter).filter(Boolean)
  const array2 = path.split(splitter).filter(Boolean)

  if (array1.filter(value => array2.includes(value)).length) return true

  // If all of the above fails, the path is definitely not active
  return false
};

export default checkIsActive;