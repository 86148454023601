import React from 'react';
import Parse from 'react-html-parser';
import { NavLink, useLocation } from 'react-router-dom';
import checkIsActive from '../../helpers/checkIsActive';

const NavButton = ({ item }) => {
   const { lxl_nav_href, lxl_nav_title, slug } = item;
   const location = useLocation();
   const props = {
      to: lxl_nav_href ? lxl_nav_href : '',
      exact: true,
      activeClassName: 'active',
      className: `${slug} nav-item lexia-reg Label`,
      isActive: () => checkIsActive(lxl_nav_href ? lxl_nav_href : '', location),
   };
   return <NavLink {...props}>{Parse(lxl_nav_title.desktop)}</NavLink>;
};

export default NavButton;
