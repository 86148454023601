import React from 'react';
import {MOBILE} from '../../constants/mediaQueries';
import styled from 'styled-components/macro';
import useMobile from '../../hooks/useMobile';
import Btn from '../../elements/Btn';
import Logo from '../Header/Logo';
import 'react-dropdown/style.css';
import {Footer} from '@lxlabs/az-pipeline-components';
import Content from './Content';

const gap = '1rem';

const FooterStyle = styled.div`
  z-index: 30;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const Overlay = styled.div`
  background-color: var(--grey);
  background-image: url('images/background.png');
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  padding: ${gap};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  > img,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
  }
  > img {
    width: auto;
    height: 100vh;
    z-index: 0;
    object-fit: cover;
    filter: blur(0.5rem);
  }
  &:after {
    content: '';
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Selector = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 50rem;
  height: auto;
  max-height: calc(100vh - calc(4 * ${gap}));
  background-color: var(--black);
  color: var(--white);
  padding: calc(2 * ${gap});
  position: relative;
  z-index: 2;
  border-radius: 1em;
  text-align: center;
  p {
    a {
      color: var(--red);
    }
  }
  @media ${MOBILE} {
    overflow: auto;
    max-height: 70vh;
    position: relative;
    top: -2rem;
  }
`;

const LogoWrap = styled.div`
  color: white;
  position: fixed;
  z-index: 20;
  top: 1.75rem;
  right: 1rem;
  width: 10vw;
  @media ${MOBILE} {
    width: 24vw;
  }
`;

const GlobalDisclaimer = ({close}) => {
  const isMobile = useMobile();

  return (
    <>
      <Overlay>
        <img src="images/body.png" alt="" role="presentation" />

        <Selector>
          <Content region={window.localStorage.getItem('lxl_region')} />
          <Btn className="transparent skinny" onClick={close}>
            Enter CVRM Pipeline Tool
          </Btn>
        </Selector>
      </Overlay>
      <LogoWrap>
        <Logo />
      </LogoWrap>
      {!isMobile && (
        <FooterStyle>
          <Footer
            links={[
              {
                url:
                  'https://www.astrazeneca.com/legal-notice-and-terms-of-use.html',
                label: 'Legal and terms of use',
              },
              {
                url: 'https://www.globalprivacy.astrazeneca.com',
                label: 'Privacy Policy',
              },
              {
                url: 'https://policy.cookiereports.com/14be610b-en-gb.html',
                label: 'Cookie Policy',
              },
              {
                 url:
                    'https://www.astrazeneca.com/our-company/contact-us.html ',
                 label: 'Contact Us',
              }
            ]}
          />
        </FooterStyle>
      )}
    </>
  );
};

export default GlobalDisclaimer;
