import React from 'react';
import styled from 'styled-components';
import {MOBILE} from '../../constants/mediaQueries';

const Title = styled.h1`
  flex-basis: 100%;
  font-size: 2rem;
  line-height: 1.5em;
  @media ${MOBILE} {
    font-size: 1.4rem;
  }
`;

function Content({ region }) {
  return (
    <div>
      <Title className="lexia-light">Content regarding the AZ CVRM Pipeline Tool</Title>
      {(region === "us") ? (
        <>
          <p>The content has been developed for US Healthcare Professionals (HCPs) who want information on AstraZeneca's CVRM Pipeline Tool.</p>
          <p>Any compound illustrated from AstraZeneca/MedImmune refers to selected pipeline compounds either still under development or to ongoing for the current portfolio.</p>
          <p>AstraZeneca/MedImmune portfolio investigational compounds are not approved by the US Food and Drug Administration, European Medicine Agency or any other regulatory agency for the uses under investigation.</p>
          <p>Some compounds, whilst still being evaluated, are already licensed for use in some indications. It should be noted that some studies may not be consistent with the current marketing authorisation and in these cases the local prescribing information should be consulted for the licensed use in your country.</p>
          <p>Information regarding any of these investigational compounds should under no circumstances be regarded as a recommendation for their use, or their safety or efficacy.</p>
          <p>All information in the tool was correct at the time of preparation but due to the ongoing nature of clinical trials, updates are frequent and for the most up-to-date information, users should refer to clinicaltrials.gov.</p>
        </>
      ) : (
        <>
          <p>The content has been developed for Healthcare Professionals (HCPs) who want information on AstraZeneca's CVRM Pipeline Tool.</p>
          <p>Any compound illustrated from AstraZeneca/MedImmune refers to selected pipeline products either still under development or to studies either completed or ongoing for the current portfolio.</p>
          <p>AstraZeneca/MedImmune portfolio products are investigational products and, as such, are not approved by the US Food and Drug Administration, European Medicine Agency or any other regulatory agency for the uses under investigation.</p>
          <p>Some products, whilst still being evaluated, are already licensed for use in some indications. It should be noted that some studies may not be consistent with the current marketing authorisation and in these cases the local prescribing information should be consulted for the licensed use in your country.</p>
          <p>Information regarding any of these products should under no circumstances be regarded as a recommendation for their use, or their safety or efficacy.</p>
          <p>All information in the tool was correct at the time of preparation but due to the ongoing nature of clinical trials, updates are frequent and for the most up-to-date information, users should refer to clinicaltrials.gov.</p>
        </>
      )}
    </div>
  );
}

export default Content;
