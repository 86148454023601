import React from 'react';

const Icon = ({ icon, size = 20, color, clickEvent, className, width = false, height = false }) => {
   const styles = {
      path: {
         fill: color,
      },
   };

   return (
      <svg
         className={`svg-icon ${className}`}
         width={`${width || size}px`}
         height={`${height || size}px`}
         viewBox="0 0 1024 1024"
         onClick={clickEvent}
      >
         <path style={styles.path} d={icon}></path>
      </svg>
   );
};

export default Icon;
