import React from 'react';
import {useStore} from 'react-context-hook';
import Parse from 'react-html-parser';
import {NavLink, useLocation} from 'react-router-dom';
import styled from 'styled-components/macro';
import {ICONS} from '../../constants';
import Icon from '../../elements/Icon';
import checkIsActive from '../../helpers/checkIsActive';
import {mobileNavHeight} from '../../constants/sizes';

const gap = '0.25rem';
const iconSize = 20;

const IconTray = styled.nav`
  position: fixed;
  bottom: 0;
  z-index: 20;
  width: 100%;
  /* ${(props) => (props.isHome ? '' : 'height: 4rem;')} */
  ul {
    background-color: var(--black);
    height: ${mobileNavHeight};

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* margin: calc(-1 * ${gap}); */
    li {
      flex-basis: calc(20% - calc(4 * ${gap}));
      margin: ${gap};
      text-align: center;
      color: var(--white);
      > * {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        padding: 0.7rem 0.25rem;
        text-align: center;
        font-size: 0.6875rem;
        line-height: 0.7rem;
        color: inherit;
        font-style: inherit;
        font-weight: inherit;
        svg {
          font-size: ${iconSize}px;
          width: 1em;
          height: 1em;
          margin-bottom: 0.25rem;
        }
        &[aria-current='page'],
        &:hover {
          color: var(--white);
        }
      }
    }
  }
`;

const MobileNavigation = () => {
  const location = useLocation();

  const [region] = useStore('region');
  // const [region, setRegion] = useStore('region');
  // const context = require(`../../_data/${region}/lxl_site.json`);

  const menu = require(`../../_data/${region}/lxl_app_menu.json`);

  const pages = ['disease-area', 'cvrm-science', 'full-pipeline', 'favourites'];

  // Filtering mobile nav items
  const mobileNav = pages.map((p) => {
    return menu && menu.filter((i) => i.slug === p)[0];
  });

  const renderIcon = (item) => {
    const mobileIcons = {
      'icon-man': 'BODY',
      'icon-comment': 'FLASK',
      'icon-beaker': 'NOTES',
      'icon-star': 'STAR',
    };
    const value = mobileIcons[item && item.lxl_nav_icon ? item.lxl_nav_icon.value : 'icon-man'];
    return ICONS[value] ? (
      <Icon icon={ICONS[value]} size={iconSize} />
    ) : (
      Parse(item && item.lxl_nav_icon ? item.lxl_nav_icon.label : '')
    );
  };

  return (
    <>
      <IconTray>
        <ul>
          {mobileNav &&
            mobileNav.map((item) => {
              return (
                <li>
                  <NavLink
                    activeClassName="active"
                    isActive={() => checkIsActive(item && item.lxl_nav_href ? item.lxl_nav_href : '', location)}
                    to={item && item.lxl_nav_href ? item.lxl_nav_href : ''}
                    className="lexia-reg"
                  >
                    {renderIcon(item)}
                    {item && item.lxl_nav_title ? item.lxl_nav_title.mobile : ''}
                  </NavLink>
                </li>
              );
            })}

          {/* {context?.region_selector?.switch_button_copy?.mobile && (
            <li>
              <button className="lexia-reg" onClick={() => setRegion(null)}>
                <Icon icon={ICONS.GLOBE} size={20} />{' '}
                {Parse(context.region_selector.switch_button_copy.mobile)}
              </button>
            </li>
          )} */}
        </ul>
      </IconTray>
    </>
  );
};

export default MobileNavigation;
