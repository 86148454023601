export default function previousPage(url, history, times = 1) {
   if (
      history.location.state &&
      history.location.state.prevPath === '/full-pipeline'
   ) {
      return '/full-pipeline';
   } else {
      let out = url;
      out = out.replace(/\/$/, '');
      for (let i = 0; i < times; i++) {
         out = out.substr(0, out.lastIndexOf('/'));
      }
      return out;
   }
}
