import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import { Theme } from '@lxlabs/az-pipeline-components';

ReactDOM.render(
  <React.StrictMode>
    <Theme theme="CVRM">
      <HashRouter>
        <App />
      </HashRouter>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();