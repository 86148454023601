import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import Breathe from '../../elements/Breathe';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTemplate from '../../components/Layout/PageTemplate';
import BackBtn from '../../elements/BackBtn';
import useMobile from '../../hooks/useMobile';

const Back = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  padding: 10rem 0 0;

  a {
    font-family: lexia;
    font-size: 1.2rem;
    color: var(--pink);
  }
`;
const NotFound = (props) => {
  const isMobile = useMobile();
  return (
    <PageTemplate title="Disease Area" content masthead={props.masthead}>
      {isMobile ? (
        props.masthead === 'solid' ? (
          <Breathe mobileBG header />
        ) : (
          <Breathe mobileBG />
        )
      ) : (
        <Breathe isCenter />
      )}
      <ContentWrapper>
        {!isMobile && <BackBtn browserBack={true} />}
        <Back>
          <h1 className="lexia-reg">404 - Page Not Found</h1>
          <Link to="/" style={{color: '#fff'}}>
            HOME
          </Link>
        </Back>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default NotFound;
