import React from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {ICONS} from '../../constants';
import Icon from '../../elements/Icon';
import previousPage from '../../helpers/previousPage';

const BackButton = () => {
  const match = useRouteMatch();
  const history = useHistory();
  if (match.url === '/') return '';
  const href = previousPage(match.url, history);
  const content = <Icon icon={ICONS.ARROW_LEFT} size={38} />;
  return (
    <Link to={href} style={{color: 'inherit'}}>
      {content}
    </Link>
  );
};

export default BackButton;
