import styled from 'styled-components/macro';
import {MOBILE} from '../constants/mediaQueries';

const Btn = styled.a`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  min-width: 7rem;
  border-radius: 0.3rem;
  padding: 1rem;
  color: var(--white);
  margin: 0 0.5rem;
  border: none;

  &.skinny {
    padding: 0.5rem;
  }
  &.icon {
    .svg-icon {
      vertical-align: sub;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.clear {
    background: var(--fog);
    border: solid 0.0625rem var(--white);
  }
  &.transparent {
    background: transparent;
    border: solid 0.0625rem var(--white);
    &:hover {
      background: var(--fog);
    }
    @media (hover: none) {
      &:hover {
        background: none;
      }
    }
  }

  &.primary {
    background: var(--dark-red);
  }
  &.secondary {
    background: var(--light-grey);
    color: var(--black);
  }
  &.sml {
    font-size: 0.975rem;
  }

  @media ${MOBILE} {
    min-width: auto;
  }
`;

export default Btn;
