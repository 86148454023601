import React from 'react';
import Parse from 'react-html-parser';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
// import { useStore } from 'react-context-hook';
import styled from 'styled-components/macro';
import { ICONS } from '../../constants';
import Icon from '../../elements/Icon';
import NavButton from './NavButton';

const Navigation = styled.ul`
   height: 100%;
   max-height: 100vh;
   overflow-y: auto;
   width: 100%;
   display: flex;
   flex-direction: column;
   li + li {
      margin-top: 0.5rem;
   }
`;

const IconWrap = styled.div`
   color: #fff;
   position: absolute;
   top: 1.25rem;
   right: 1.25rem;
   width: 1.25rem;
   height: 1.25rem;
   cursor: pointer;
`;

const Submenu = styled.ul`
   margin-top: 1rem;
   li {
      padding: 0.4rem 0 0.4rem 2.5rem;
      a {
         font-family: lexia-light;
         font-size: 0.8rem;
         color: var(--light-grey);
      }
   }
`;

const NavBar = ({ navButtons, active, toggle }) => {
   /* eslint-disable no-unused-vars */
   // const [region, setRegion] = useStore('region');
   /* eslint-disable no-unused-vars */
   // const context = require(`../../_data/${region}/lxl_site.json`);

   return (
      <motion.nav
         className="navigation-wrap"
         initial={{ x: -160 }}
         animate={{
            x: active ? 0 : -160,
         }}
         transition={{ ease: 'easeOut' }}
      >
         <Navigation className="stack">
            <IconWrap onClick={toggle}>
               <Icon icon={ICONS.CLOSE} />
            </IconWrap>
            {navButtons.map((button) => (
               <li key={button.ID}>
                  <NavButton item={button} />
                  {button.lxl_submenu && (
                     <Submenu>
                        {button.lxl_submenu.map((sub, i) => {
                           return (
                              <li key={i}>
                                 <Link to={sub.lxl_nav_href ? sub.lxl_nav_href : ''} replace>
                                    {Parse(sub.lxl_nav_title.desktop)}
                                 </Link>
                              </li>
                           );
                        })}
                     </Submenu>
                  )}
               </li>
            ))}
            {/* {context?.region_selector?.switch_button_copy?.desktop && (
               <li key="switch-regions">
                  <button
                     className="nav-item lexia-reg Label switch"
                     onClick={() => setRegion(null)}
                  >
                     {context.region_selector.switch_button_copy.desktop}
                  </button>
               </li>
            )} */}
         </Navigation>
      </motion.nav>
   );
};

export default NavBar;
