import React from 'react';
import {useStore} from 'react-context-hook';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {mastheadHeight} from '../../constants/sizes';
import useMobile from '../../hooks/useMobile';
import NavBar from '../Navigation';
import BackButton from './BackButton';
import Logo from './Logo';
import NavButton from './NavButton';

const SiteHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: ${mastheadHeight};
  padding: 0 1rem;
  color: var(--white);
  &.transparent {
    background-color: transparent;
  }
  &.solid {
    background-color: var(--black);
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  height: ${mastheadHeight};
  .homelink {
    margin-left: auto;
  }
  .logo-container {
    width: 10vw;
    min-width: 5.625rem;
    max-width: 11.875rem;
    color: var(--white);
  }
`;

const Header = ({active, masthead, toggleNavigation}) => {
  const isMobile = useMobile();
  const [region] = useStore('region');
  const classes = `site-header ${masthead}`;
  const navData = require(`../../_data/${region}/lxl_app_menu.json`);

  const navConfig = navData.filter((item) => {
    return item.menu_item_parent === 0;
  });

  return (
    <>
      <SiteHeader className={classes}>
        <Inner>
          {isMobile && <BackButton />}
          {!isMobile && <NavButton onClick={toggleNavigation} />}
          <Link to="/" className="homelink logo-container">
            <Logo />
          </Link>
        </Inner>
      </SiteHeader>
      <NavBar
        navButtons={navConfig}
        active={active}
        toggle={toggleNavigation}
      />
    </>
  );
};

export default Header;
