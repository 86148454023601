import {
   LAPTOP as LAPTOP_BREAK,
   PHONE_MAX,
   TABLET as TABLET_BREAK,
} from './breakpoints';

export const MOBILE = `(max-width: ${TABLET_BREAK}px)`;
export const TABLET = `(min-width: ${PHONE_MAX + 1}px) and (max-width: ${TABLET_BREAK}px)`;
export const LAPTOP = `(min-width: ${TABLET_BREAK + 1}px) and (max-width: ${LAPTOP_BREAK}px`;
export const DESKTOP = `(min-width: ${LAPTOP_BREAK + 1}px)`;
export const SHALLLOWDESKTOP = `(min-width: ${TABLET_BREAK + 1}px) and (max-height: 550px)`;
