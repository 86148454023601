import React from 'react';
import styled from 'styled-components/macro';

const stroke = 0.1875;
const gap = 0.3125;
const width = {
  sml: '1.25rem',
  med: '1.5625rem',
  lrg: '1.875rem',
}
const BurgerToggle = styled.button`
  padding: 1rem;
  margin: -1rem;
  i {
      position: relative;
      display: block;
      color: var(--white);
      width: ${width.med};
      height: ${stroke}rem;
      background-color: currentColor;
      transition: ease 0.4s;
      &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        height: ${stroke}rem;
        background-color: currentColor;
        transition: inherit;
      }
      &:before {
        width: ${width.lrg};

        top: -${gap + stroke}rem;
      }
      &:after {
        width: ${width.sml};
        top: ${gap + stroke}rem;
      }
    }
    @media (hover: hover) {
      &:hover i {
        &:before {
          width: ${width.sml};
        }
        &:after {
          width: ${width.lrg};
        }
      }
    }
    
`;
const NavButton = ({onClick}) => <BurgerToggle onClick={onClick}><i></i></BurgerToggle>

export default NavButton