
import {Plugins} from '@capacitor/core';
import {ScreenOrientation} from '@ionic-native/screen-orientation';
import React, {lazy, Suspense, useEffect, useState} from 'react';
import TagManager from 'react-gtm-module';
import {useStore, withStore} from 'react-context-hook';
import {Route, Switch} from 'react-router-dom';

import NotFound from './pages/NotFound';
import GlobalStyles from './styles/GlobalStyles';
import GlobalDisclaimer from './components/GlobalDisclaimer';

import * as dotenv from 'dotenv'
dotenv.config()

console.log('🏔️', process.env)

const tagManagerArgs = {
  gtmId: 'GTM-M8G3GRK',
};

TagManager.initialize(tagManagerArgs);

const Home = lazy(() => import('./pages/Home'));
const DiseaseArea = lazy(() => import('./pages/DiseaseArea'));
const EvidenceProgrammes = lazy(() => import('./pages/EvidenceProgrammes'));
const EvidenceProgrammeDetail = lazy(() =>
  import('./pages/EvidenceProgrammes/Programme')
);
const Area = lazy(() => import('./pages/DiseaseArea/Area'));
const FullPipeline = lazy(() => import('./pages/FullPipeline'));
const Favourites = lazy(() => import('./pages/Favourites'));
// const AdditionalResources = lazy(() => import('./pages/AdditionalResources'));
const Abbreviations = lazy(() => import('./pages/Abbreviations'));
const CompoundDetails = lazy(() =>
  import('./pages/FullPipeline/CompoundDetails')
);
const TrialDetails = lazy(() => import('./pages/FullPipeline/TrialDetails'));
const Narrative = lazy(() => import('./pages/Narrative'));
const Partnering = lazy(() => import('./pages/Partnering'));

const renderLoader = () => <h2>Loading...</h2>;

const HomeComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <Home {...props} masthead="transparent" />
  </Suspense>
);
const DiseaseComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <DiseaseArea {...props} masthead="transparent" />
  </Suspense>
);
const EvidencePage = (props) => (
  <Suspense fallback={renderLoader()}>
    <EvidenceProgrammes {...props} masthead="transparent" />
  </Suspense>
);
const EvidenceProgrammePage = (props) => (
  <Suspense fallback={renderLoader()}>
    <EvidenceProgrammeDetail {...props} masthead="solid" />
  </Suspense>
);
const PipelineComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <FullPipeline {...props} masthead="solid" />
  </Suspense>
);
const NarrativeComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <Narrative {...props} masthead="solid" />
  </Suspense>
);
const PartneringComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <Partnering {...props} masthead="solid" />
  </Suspense>
);
const FavouritesComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <Favourites {...props} masthead="solid" />
  </Suspense>
);
const CompoundDetailComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <CompoundDetails {...props} masthead="solid" />
  </Suspense>
);
const TrialDetailComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <TrialDetails {...props} masthead="solid" />
  </Suspense>
);
const AreaComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <Area {...props} masthead="solid" />
  </Suspense>
);
const NotFoundComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <NotFound {...props} masthead="solid" />
  </Suspense>
);
// const AdditionalResourcesComponent = (props) => (
//    <Suspense fallback={renderLoader()}>
//       <AdditionalResources {...props} masthead="solid" />
//    </Suspense>
// );
const AbbreviationsComponent = (props) => (
  <Suspense fallback={renderLoader()}>
    <Abbreviations {...props} masthead="solid" />
  </Suspense>
);

const Routes = [
  {path: '/', exact: true, render: HomeComponent},
  {path: '/disease-area', exact: true, render: DiseaseComponent},
  {path: '/full-pipeline', exact: true, render: PipelineComponent},
  {path: '/favourites', exact: true, render: FavouritesComponent},
  // {
  //    path: '/additional-resources',
  //    exact: true,
  //    render: AdditionalResourcesComponent,
  // },
  {path: '/glossary', exact: true, render: AbbreviationsComponent},
  {path: '/disease-area/:area', exact: true, render: AreaComponent},
  {
    path: '/disease-area/:area/:slug',
    exact: true,
    render: CompoundDetailComponent,
  },
  {
    path: '/disease-area/:area/:slug/:id',
    exact: true,
    render: TrialDetailComponent,
  },
  //   {
  //     path: '/disease-area/:area/:slug/:nct',
  //     exact: true,
  //     render: TrialDetailComponent,
  //   },
  {
    path: '/full-pipeline/:slug/:nct',
    exact: true,
    render: TrialDetailComponent,
  },
  {
    path: '/cvrm-science/:name/:sub',
    render: NarrativeComponent,
    exact: true,
  },
  {
    path: '/cvrm-science/:name?',
    render: NarrativeComponent,
    exact: false,
  },
  {
    path: '/partnering/:name?',
    render: PartneringComponent,
    exact: false,
  },
  {path: '/evidence-programmes', exact: true, render: EvidencePage},
  {
    path: '/evidence-programmes/:name?',
    render: EvidenceProgrammePage,
    exact: false,
  },
];

const handleIos = async (Plugins, ScreenOrientation) => {
  if (!Plugins || !ScreenOrientation) return;
  const {Device, StatusBar} = Plugins;
  const info = await Device.getInfo();

  const {model, platform} = info;
  if (platform !== 'ios') return;

  const {LANDSCAPE_PRIMARY, PORTRAIT} = ScreenOrientation.ORIENTATIONS;

  if (model === 'iPhone') {
    console.log('📱  iPhone:', info);
    ScreenOrientation.lock(PORTRAIT);
    document.getElementById('CookieReportsPanel').style.display = 'none';
  }
  if (model === 'iPad') {
    console.log('🖥  iPad:', info);
    ScreenOrientation.lock(LANDSCAPE_PRIMARY);
  }

  if (StatusBar) {
    StatusBar.hide();
  }
};

const App = () => {
  const [region] = useStore('region');
  const [showGlobalDisc, setShowGlobalDisc] = useState();

  handleIos(Plugins, ScreenOrientation);

  useEffect(() => {
    if (localStorage.getItem('compounds') === null) {
      localStorage.setItem('compounds', JSON.stringify([]));
    }
    if (localStorage.getItem('trials') === null) {
      localStorage.setItem('trials', JSON.stringify([]));
    }

    localStorage.setItem('lxl_region', region);

    if (!localStorage.getItem('globaldisc')) {
      localStorage.setItem('globaldisc', 'true');
    }
    setShowGlobalDisc(localStorage.getItem('globaldisc'));
  }, [region]);

  const closeGlobalDisc = () => {
    localStorage.setItem('globaldisc', 'false');
    setShowGlobalDisc(localStorage.getItem('globaldisc'));
  };

  return (
    <>
      <GlobalStyles />

      <main className="wrapper stack min-h-screen">
        <Switch>
          {Routes.map((x, i) => (
            <Route key={i} path={x.path} exact={x.exact} component={x.render} />
          ))}
          <Route component={NotFoundComponent} />
        </Switch>
      </main>

      {showGlobalDisc === 'true' && (
        <GlobalDisclaimer close={closeGlobalDisc} />
      )}
    </>
  );
};

const initialState = { region: [ process.env.REACT_APP_REGION || 'global' ] };
export default withStore(App, initialState);
