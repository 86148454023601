import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ICONS } from '../constants';
import previousPage from '../helpers/previousPage';
import Icon from './Icon';

const gap = '0.125rem'
const iconSize = 20

const ButtonWrapper = styled.div`
    margin-top: 4.5vh;
    margin-right: calc(calc(-2 * ${gap}) - 0.2rem);
    margin-bottom: 4.5vh;
    margin-left: calc(calc(-2 * ${gap}) - 0.2rem);
   [data-back] {
      display: inline-flex;
      align-items: center;
      margin: calc(-1 * ${gap});
      padding: calc(2 * ${gap});
      font-family: Arial, Helvetica, sans-serif;
      color: var(--white);
      transition: all 0.2s ease-in-out;
      >* {
        margin: ${gap};
      }
      >span {
         text-decoration: underline;
      }
   }
`;

const BackBtn = ({ text = 'Back', className = "back-button", browserBack = false }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const content = <><Icon icon={ICONS.ARROW_LEFT} size={iconSize} /><span>{text}</span></>
  return (
    <ButtonWrapper>
      {browserBack && <button data-back className={className} onClick={history.goBack}>{content}</button>}
      {!browserBack && <Link data-back className={className} to={previousPage(match.url, history)}>{content}</Link>}
    </ButtonWrapper>
  );
};

export default BackBtn;
